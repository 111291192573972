import React, { forwardRef } from "react";
import { cn } from "../utils/classNames";

const Stop = forwardRef(({ className, ...props }, ref) => (
  <svg ref={ref} {...props} className={cn("", className)} width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 16V8C6 7.45 6.19583 6.97917 6.5875 6.5875C6.97917 6.19583 7.45 6 8 6H16C16.55 6 17.0208 6.19583 17.4125 6.5875C17.8042 6.97917 18 7.45 18 8V16C18 16.55 17.8042 17.0208 17.4125 17.4125C17.0208 17.8042 16.55 18 16 18H8C7.45 18 6.97917 17.8042 6.5875 17.4125C6.19583 17.0208 6 16.55 6 16ZM8 16H16V8H8V16Z"
      fill="currentColor"
    />
  </svg>
));

Stop.displayName = "Stop";
export default Stop;
