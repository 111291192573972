import React, { forwardRef } from "react";
import { cn } from "../utils/classNames";

const Google = forwardRef(({ className, ...props }, ref) => (
  <svg ref={ref} {...props} className={cn("", className)} width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.7141 12.2244C23.7141 11.2412 23.6343 10.5236 23.4616 9.77954H12.2336V14.2175H18.8242C18.6914 15.3204 17.9739 16.9814 16.3793 18.0975L16.357 18.246L19.907 20.9962L20.153 21.0208C22.4118 18.9346 23.7141 15.8652 23.7141 12.2244Z"
      fill="#4285F4"
    />
    <path
      d="M12.2336 23.9176C15.4624 23.9176 18.1731 22.8545 20.153 21.0209L16.3793 18.0976C15.3695 18.8018 14.0141 19.2934 12.2336 19.2934C9.07121 19.2934 6.38715 17.2074 5.43035 14.324L5.29011 14.3359L1.59869 17.1927L1.55042 17.3269C3.51695 21.2334 7.55637 23.9176 12.2336 23.9176Z"
      fill="#34A853"
    />
    <path
      d="M5.43029 14.324C5.17783 13.58 5.03173 12.7826 5.03173 11.9589C5.03173 11.135 5.17783 10.3378 5.41701 9.59366L5.41032 9.43519L1.67264 6.53247L1.55035 6.59064C0.739849 8.21174 0.27478 10.0322 0.27478 11.9589C0.27478 13.8855 0.739849 15.7059 1.55035 17.327L5.43029 14.324Z"
      fill="#FBBC05"
    />
    <path
      d="M12.2336 4.62403C14.4792 4.62403 15.9939 5.59401 16.8577 6.40461L20.2327 3.10928C18.1599 1.1826 15.4624 0 12.2336 0C7.55637 0 3.51695 2.68406 1.55042 6.59056L5.41707 9.59359C6.38715 6.7102 9.07121 4.62403 12.2336 4.62403Z"
      fill="#EB4335"
    />
  </svg>
));

Google.displayName = "Google";
export default Google;
