import React, { forwardRef } from "react";
import { cn } from "../utils/classNames";

const Star = forwardRef(({ className, ...props }, ref) => (
  <svg ref={ref} {...props} className={cn("", className)} width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6087 17.6954L8.45866 20.1954C8.27532 20.312 8.08366 20.362 7.88366 20.3454C7.68366 20.3287 7.50866 20.262 7.35866 20.1454C7.20866 20.0287 7.09199 19.8829 7.00866 19.7079C6.92532 19.5329 6.90866 19.337 6.95866 19.1204L8.05866 14.3954L4.38366 11.2204C4.21699 11.0704 4.11282 10.8995 4.07116 10.7079C4.02949 10.5162 4.04199 10.3287 4.10866 10.1454C4.17532 9.96203 4.27532 9.81203 4.40866 9.69536C4.54199 9.57869 4.72532 9.50369 4.95866 9.47036L9.80866 9.04536L11.6837 4.59536C11.767 4.39536 11.8962 4.24536 12.0712 4.14536C12.2462 4.04536 12.4253 3.99536 12.6087 3.99536C12.792 3.99536 12.9712 4.04536 13.1462 4.14536C13.3212 4.24536 13.4503 4.39536 13.5337 4.59536L15.4087 9.04536L20.2587 9.47036C20.492 9.50369 20.6753 9.57869 20.8087 9.69536C20.942 9.81203 21.042 9.96203 21.1087 10.1454C21.1753 10.3287 21.1878 10.5162 21.1462 10.7079C21.1045 10.8995 21.0003 11.0704 20.8337 11.2204L17.1587 14.3954L18.2587 19.1204C18.3087 19.337 18.292 19.5329 18.2087 19.7079C18.1253 19.8829 18.0087 20.0287 17.8587 20.1454C17.7087 20.262 17.5337 20.3287 17.3337 20.3454C17.1337 20.362 16.942 20.312 16.7587 20.1954L12.6087 17.6954Z"
      fill="currentColor"
    />
  </svg>
));

Star.displayName = "Star";
export default Star;
